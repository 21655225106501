import { connect } from 'simpply';
import appKey from 'util/auth';

//If user has proper permissions, display link in navigation
const AuthorizedFeature = ({ permissions, user, children, app = appKey.XCALIBUR }) => {
  if (!Object.keys(user).length) {
    return null;
  }

  if (user.authorizedApps.x?.indexOf('admin') > -1) {
    return children;
  }
  console.log(`>>> user.authorizedApps: ${JSON.stringify(user.authorizedApps)}`)
  const userPermission = user.authorizedApps[app] || [];
  const intersection = permissions.filter((p) => userPermission.indexOf(p) > -1);
  return intersection.length ? children : null;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AuthorizedFeature);

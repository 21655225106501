const setBrands = (_, payload) => payload;

const effects = {
  SET_BRANDS: setBrands
};

export default {
  initialState: null,
  effects
};

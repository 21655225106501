const APP_BASE_URL = window.location.origin;

export const API_BASE_URL =
  APP_BASE_URL.indexOf('xcalibur-dev') > 0 || APP_BASE_URL.indexOf('localhost') > 0
    ? 'https://api.xcalibur-dev.xmode.io'
    : 'https://api.xcalibur.xmode.io';

export const getEnvironmentName = () => {
  return APP_BASE_URL.indexOf('xcalibur-dev') > 0 || APP_BASE_URL.indexOf('localhost') > 0 ? 'dev' : 'prod';
};

const setCompanies = (_, payload) => payload;

const effects = {
  SET_COMPANIES: setCompanies
};

export default {
  initialState: null,
  effects
};

const setExchanges = (_, payload) => payload;

const effects = {
  SET_EXCHANGES: setExchanges
};

export default {
  initialState: null,
  effects
};

import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { getEnvironmentName } from 'util/environment';

import './styles.css';
import Tooltip from '@material-ui/core/Tooltip';

const AppHeader = () => {
  const [isLogged, setIsLogged] = useState(true);

  const onLogOutClick = () => {
    localStorage.removeItem('token');
    setIsLogged(false);
  };

  if (!isLogged) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return (
    <AppBar className="app-header" position="static">
      <Toolbar className="u-fx u-fx-align-center u-fx-justify-space-between">
        <Typography variant="h6" color="inherit">
          <Link className="no-deco" to="/">
            Xcalibur Dashboard {getEnvironmentName() === 'dev' ? '- DEVELOPMENT' : ''}
          </Link>
        </Typography>
        <Tooltip title="Log Out">
          <Button variant="contained" color="secondary" onClick={onLogOutClick}>
            Log Out
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;

import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import { createProvider } from 'simpply';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import LazyComponent from 'components/LazyComponent/LazyComponent';
import AuthenticatedRoute from 'components/Auth/AuthenticatedRoute';
import systemStorage from './storageEntities';
import './index.css';

const LazyLogin = lazy(() => import('views/Login/Login'));
const LazyHome = lazy(() => import('views/Home'));
const Polygon = lazy(() => import('views/Polygon'));
const Enterprise = lazy(() => import('views/Enterprise'));
const Marketing = lazy(() => import('views/Marketing'));
const Admin = lazy(() => import('views/Admin'));
const LazyNotFound = lazy(() => import('views/NotFound/NotFound'));
// const LazySpiderTrace = lazy(() => import('views/Xperiment/SpiderTrace'));

const AppProvider = createProvider(systemStorage, {
  logging: false,
});

ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <AppProvider>
      <Router>
        <ScrollToTop>
          <main>
            <Routes>
              <Route
                exact
                path="/login"
                element={
                  localStorage.getItem('token') ? (
                    <Navigate
                      to={{
                        pathname: '/',
                      }}
                    />
                  ) : (
                    <LazyComponent component={LazyLogin} />
                  )
                }
              />
              <Route path="/" element={<AuthenticatedRoute exact path="/" component={LazyHome} name="home" />} />
              <Route path="/polygon/*" element={<AuthenticatedRoute path="/polygon" component={Polygon} name="Polygon" />} />
              <Route path="/enterprise/*" element={<AuthenticatedRoute path="/enterprise" component={Enterprise} name="Enterprise" />} />
              <Route path="/marketing/*" element={<AuthenticatedRoute path="/marketing" component={Marketing} name="Marketing" />} />
              <Route path="/admin/*" element={<AuthenticatedRoute path="/admin" component={Admin} name="Admin" />} />
              <Route element={LazyNotFound} />
              {/* <AuthenticatedRoute exact path="/xperiment/spidergraph" component={LazySpiderTrace} name="spider-trace" /> */}
            </Routes>
          </main>
        </ScrollToTop>
      </Router>
    </AppProvider>
  </SnackbarProvider>,
  document.getElementById('root')
);

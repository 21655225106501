import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import AppHeader from 'components/Navigation/AppHeader';
import CollapsableMenu from 'components/Navigation/CollapsableMenu';
import GeneralUserRequests from 'components/Auth/GeneralUserRequests';

const AuthenticateRoute = ({ component: Component, name, ...rest }) => {
  const location = useLocation();
  return localStorage.getItem('token') ? (
    <div className={`page page-${name} u-fx u-fx-column`}>
      <AppHeader />
      <div className={`page__content page-${name}__content u-fx`}>
        <CollapsableMenu />
        <div className="page__working-space u-fx u-fx-column">
          <GeneralUserRequests component={Component} {...rest}/>
        </div>
      </div>
    </div>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
}


export default AuthenticateRoute;

import appKey from 'util/auth';

// Polygon -> X-change
export const polygonLinks = [
  {
    text: 'Dashboard',
    url: '/polygon/dashboard',
    app: appKey.XPAND,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Reports',
    url: '/polygon/reports',
    app: appKey.XCHANGE,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Categories',
    url: '/polygon/categories',
    app: appKey.XCHANGE,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Brands',
    url: '/polygon/brands',
    app: appKey.XCHANGE,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Companies',
    url: '/polygon/companies',
    app: appKey.XCHANGE,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Load Geometries',
    url: '/polygon/geometries',
    app: appKey.XCHANGE,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Geom Browser',
    url: '/polygon/geombrowse',
    app: appKey.XCHANGE,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
];

//Marketing -> X-clude
export const marketingLinks = [
  {
    text: 'Deletion',
    url: '/marketing/deletion-request',
    app: appKey.XCLUDE,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'D-N-S',
    url: '/marketing/do-not-sell',
    app: appKey.XCLUDE,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
];

//Enterprise -> x-tract/x-port
export const enterpriseLinks = [
  {
    text: 'Vendors',
    url: '/enterprise/vendors',
    app: appKey.XTRACT,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Custom Eval',
    url: '/enterprise/customeval',
    app: appKey.XTRACT,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Eval Access',
    url: '/enterprise/evalaccess',
    app: appKey.XTRACT,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Export API',
    url: '/enterprise/export-api',
    app: appKey.XPORT,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Realtime',
    url: '/enterprise/realtime-export',
    app: appKey.XPORT,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Daily Export',
    url: '/enterprise/daily-export',
    app: appKey.XPORT,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Snowflake',
    url: '/enterprise/snowflake',
    app: appKey.XPORT,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
  {
    text: 'Matchbook Evals',
    url: '/enterprise/matchbook-eval',
    app: appKey.XTRACT,
    permissions: ['read', 'write', 'admin'],
    isPadded: true,
  },
];

// export const xperimentLinks = [
//   {
//     text: 'Spider Trace',
//     url: '/xperiment/spidergraph',
//     app: appKey.XPERIMENT,
//     permissions: ['read', 'write', 'admin'],
//     isPadded: true,
//   },
// ];

export const adminLinks = [
  {
    text: 'User Management',
    url: '/admin/user-management',
    app: appKey.XCALIBUR,
    permissions: ['admin'],
    isPadded: true,
  },
];

const appKey = {
  XCALIBUR: 'x',
  XCHANGE: 'xc',
  XTRACT: 'xt',
  XCLUDE: 'xx',
  XPAND: 'xp',
  XPORT: 'xr',
  XPERIMENT: 'xm',
};

export default appKey;

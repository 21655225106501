const setUser = (_, payload) => payload;

const effects = {
  SET_USER: setUser
};

export default {
  initialState: {},
  effects
};

import React, { useEffect, Suspense } from 'react';
import { useSnackbar } from 'notistack';
import { connect } from 'simpply';
import decode from 'jwt-decode';
import LoadingMessage from 'components/LoadingMessage/LoadingMessage';

const GeneralUserRequests = ({
  component: Component,
  user,
  categories,
  brands,
  companies,
  exchanges,
  dispatch,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const token = localStorage.getItem('token');

    dispatch({
      type: 'SET_USER',
      payload: decode(token),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerNotification = (message, variant = 'info') => {
    enqueueSnackbar(message, {
      variant,
      preventDuplicate: true,
    });
  };

  if (!Object.keys(user).length) {
    return null;
  }

  return (
    <Suspense fallback={<LoadingMessage />}>
      <Component  {...rest} triggerNotification={triggerNotification} />
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(GeneralUserRequests);

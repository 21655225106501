import categories from './categories';
import brands from './brands';
import companies from './companies';
import exchanges from './exchanges';
import user from './user';
import { createSystemStorage } from 'simpply';

export default createSystemStorage({
  categories,
  brands,
  companies,
  exchanges,
  user
});

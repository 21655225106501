const setCategories = (_, payload) => payload;

const effects = {
  SET_CATEGORIES: setCategories
};

export default {
  initialState: null,
  effects
};
